<template>
  <b-container data-aos="fade-up">
    <b-img-lazy
      class="w-100"
      alt="One of the drivers stood with a passenger"
      src="../../assets/thaw-passengers.jpg"
    />
    <h1 class="mt-5 mb-4 text-primary">Contact Us</h1>
    <p>
      <i>
        Our volunteer drivers all live locally and are here to help residents as
        needed. Why not get in touch and register for our scheme – you never
        know when you might need us!
      </i>
    </p>
    <p>To contact us you can reach us by phone by calling <a href="tel:+447717178800">07717-17-88-00</a> or email us at <a href="mailto:thwvcs@gmail.com">thwvcs@gmail.com</a>.</p>
  </b-container>
</template>

<script>
export default {
  name: "ContactUs",
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
